import './sass/vitrine.scss';

import "swiped-events";
import smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import {Ensure} from "./js/common";
import cookie from "cookiejs";

smoothscroll.polyfill();

window.renderCartHeader = function (html, action = 'updated') {
    const cartToaster = $('.cart-toaster');

    $('.cart').html(html);

    const miniCartContainer = document.querySelector('.miniCart__container');
    miniCartContainer?.classList.add('active');
    setTimeout(() => {
        miniCartContainer?.classList.remove('active');
    }, 2000);

    if (window.matchMedia("(max-width: 998px)").matches) {
        const showToaster = function (action = 'updated') {
            if ('updated' === action) {
                cartToaster.html('Quantidade atualizada');
            } else if ('added' === action) {
                cartToaster.html('Produto adicionado');
            }
            cartToaster.addClass('cart-toaster--active');
            setTimeout(() => {
                cartToaster.removeClass('cart-toaster--active');
            }, 2000);
        }

        window.cartMiniAjustHeight();
        if ('updated' === action) {
            $('.js-cart:visible').find('.cart__updated-qty').addClass('ripple');
            setTimeout(() => {
                $('.js-cart:visible').find('.cart__updated-qty').removeClass('ripple');
            }, 2000);
            showToaster(action);
        } else if ('added' === action) {
            $('.js-cart:visible').find('.cart__count').addClass('ripple');
            setTimeout(() => {
                $('.js-cart:visible').find('.cart__count').removeClass('ripple');
            }, 2000);
            showToaster(action)
        }
    }

    //   reset event listeners
    if (!miniCartContainer) return;

    const handleMouseOver = function () {
        miniCartContainer.classList.add('active');
    };
    const handleMouseLeave = function () {
        miniCartContainer.classList.remove('active');
    };

    miniCartContainer.addEventListener('mouseover', handleMouseOver);
    miniCartContainer.addEventListener('mouseleave', handleMouseLeave);
}

window.handleAbandonedCart = function () {
    if ('object' === typeof sendinblue) {
        $.getJSON('/bebida/abandoned_cart.php', function (data) {
            if (
                null !== data
                && !$.isEmptyObject(data)
            ) {
                console.log(data);
                sendinblue.track(
                    data.action,
                    {
                        "email": data.customer_email
                    },
                    {
                        "id": data.cart.id,
                        "data": data.cart
                    }
                );
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', _ => {
    /** Add to Calendar - P�gina de eventos */
    (async _ => {
        const addToCalendarButton = document.getElementsByTagName('add-to-calendar-button');
        if (addToCalendarButton.length > 0) {
            await import('add-to-calendar-button');
        }
    })();

    /** Cancela transa��es pendentes do Sitef */
    (async _ => {
        const totemPopup = document.querySelector('.totemPopup');
        const qrcodeCookie = cookie.get('qrcode');

        if (
            totemPopup
            && !qrcodeCookie
        ) {
            const dayjs = require('dayjs');
            const sitefRequest = (await import('./js/components/sitefRequest.js')).default;
            const sitef = new sitefRequest(totemPopup.dataset.storeId, 'TO000001');
            let pendingTransaction = new FormData();

            let clisitefStatus;
            let continueTransactionResponse;
            let finishTransactionResponse;
            let qtyPendingTransactions;
            let pendingTransactions = [];

            const startTransactionResponse = await sitef.send('startTransaction', {
                'functionId': 130,
                'trnAmount': 0,
                'taxInvoiceNumber': dayjs().format('HHmmss'),
                'taxInvoiceDate': dayjs().format('YYYYMMDD'),
                'taxInvoiceTime': dayjs().format('HHmmss'),
                'trnAdditionalParameters': "",
                'trnInitParameters': `[ParmsClient=1=${totemPopup.dataset.storeCnpj};2=${totemPopup.dataset.automationCnpj}]`,
            });
            console.table({...{'date': new Date(), 'action': 'start'}, ...startTransactionResponse});

            clisitefStatus = startTransactionResponse.clisitefStatus;
            while (10000 === clisitefStatus) {
                continueTransactionResponse = await sitef.send('continueTransaction', {
                    'sessionId': startTransactionResponse.sessionId,
                    'continue': 0,
                    'data': '',
                });

                console.table({...{'date': new Date(), 'action': 'continue'}, ...continueTransactionResponse});

                switch (continueTransactionResponse.clisitefStatus) {
                    case 0:
                        for (let i = 0; i < pendingTransactions.length; i++) {
                            pendingTransaction = Object.fromEntries(pendingTransactions[i].entries());
                            finishTransactionResponse = await sitef.send('finishTransaction', {
                                'sessionId': startTransactionResponse.sessionId,
                                'taxInvoiceNumber': pendingTransaction[160],
                                'taxInvoiceDate': pendingTransaction[163],
                                'taxInvoiceTime': pendingTransaction[164],
                                'confirm': 0,
                            });
                            console.table({...{'date': new Date(), 'action': 'finish'}, ...finishTransactionResponse});
                        }
                        pendingTransactions.forEach(t => {
                            console.log(Object.fromEntries(t.entries()))
                        });

                        console.log(pendingTransactions);
                        // debugger
                        // const finishTransactionResponse = await sendSitefResquest('finishTransaction', {
                        //     'sessionId': startTransactionResponse.sessionId,
                        //     'taxInvoiceNumber': orderId,
                        //     'taxInvoiceDate': invoiceDate,
                        //     'taxInvoiceTime': invoiceTime,
                        // });

                        // console.table({...{'date': new Date(), 'action': 'finish'}, ...finishTransactionResponse});
                        return;

                    default:
                        break;
                }

                switch (continueTransactionResponse.fieldId) {
                    case 210:
                        qtyPendingTransactions = parseInt(continueTransactionResponse.data, 10);
                        break;

                    case 160:
                    case 161:
                    case 163:
                    case 164:
                    case 211:
                        pendingTransaction.append(continueTransactionResponse.fieldId, continueTransactionResponse.data);
                        break;

                    case 1319:
                        pendingTransaction.append(continueTransactionResponse.fieldId, continueTransactionResponse.data);
                        pendingTransactions.push(pendingTransaction);
                        pendingTransaction = new FormData();
                        break;

                    default:
                        break
                }

                clisitefStatus = continueTransactionResponse.clisitefStatus;
            }
        }
    })();

    /** Countdown Tem�tico */
    (async _ => {
        const countDown = $('[data-countdown]');

        if (countDown.length > 0) {
            const finalDate = countDown.data('countdown');
            const totalSeconds = countDown.data('total');
            const isNext = document.querySelector('.thematicCountdown')?.classList.contains('thematicCountdown--next')
                || document.querySelector('.thematicCountdown')?.classList.contains('thematicCountdown--daysLeft');
            const setRulerPosition = position => {
                const rulerSection = document.querySelectorAll('.thematicCountdown__ruler-section');
                const positionString = position + '%';

                [...rulerSection].forEach(ruler => {
                    if (ruler) {
                        ruler.style.setProperty('--position', positionString); // `${position}%`);
                    }
                });
            };

            countDown.countdown(finalDate, function (event) {
                const position = (100 * (1 - event.offset.totalSeconds / totalSeconds)).toFixed(1);
                let format = '%-Dd %H:%M:%S';

                setRulerPosition(position);

                if (isNext) {
                    format = '';
                    if (event.offset.totalDays > 0) {
                        format += '<span class="nowrap">%-D dia%!D </span>';
                    }
                    if (event.offset.totalHours > 0) {
                        format += '<span class="nowrap">%-H hora%!H </span>';
                    }
                    format += '<span class="nowrap">%-M min</span>';
                    if (event.offset.totalDays === 0) {
                        format += ' <span class="nowrap">%-S seg</span>';
                    }
                } else if (event.offset.totalDays < 1) {
                    format = '%H:%M:%S';
                }
                countDown.html(event.strftime(format));
            });
        }
    })();

    /** Countdown Ofertas */
    const renderOffers = async _ => {
        const countDown = document.querySelectorAll('[data-offer-end-at]:not([data-offer-end-at=""])');
        [...countDown].forEach(el => {
            const countDownEl = $(el);
            const finalDate = el.dataset?.offerEndAt || null;

            if (finalDate) {
                countDownEl.countdown(finalDate, function (e) {
                    let format = '%-Dd %H:%M:%S';

                    if (e.offset.totalDays < 1) {
                        format = '%H:%M:%S';
                    }

                    countDownEl.html(e.strftime(format));
                });
            }
        });
    };
    renderOffers();

    /** Carross�is */
    (async _ => {
        await import('slick-carousel/slick/slick.css', {
            assert: {type: 'css'}
        });

        await import('slick-carousel');
        const carousel = (await import('./js/components/carouselComponent.js')).default;
        await import('./js/components/tabsCategoryComponent');

        (new carousel()).load();
    })();

    $('input[name="cart_quantity[]"], input[name="cart_quantity"]').mask('00000');
    $('input[name="pfrom"], input[name="pto"]').mask('0000000');

    function handlePopup(form) {
        if (
            form.hasClass('js-showPopupHnkGelando')
            && false === cookie.get('popup-heineken-gelando-10-24')
        ) {
            $.fancybox.open({
                src: '/bebida/popup.php',
                type: 'ajax',
                autoFocus: false
            });
        }
    }

    const sendMoengageEvent = (eventName, eventData) => {
        try {
            if ('undefined' !== typeof Moengage) {
                Object.entries(eventData).forEach(([key, value]) => {
                    if (!value) {
                        delete (eventData[key]);

                        return;
                    }
                    if (/_name$/.test(key)) {
                        eventData[key] = decodeURIComponent(value)
                    }
                });
                Moengage.track_event(eventName, eventData);
            }
        } catch (e) {
            console.error(e);
        }
    };

    $('.cart').on('click', '.js-cartProductRemove', async function (e) {
        const isVitrine = window.location.href.endsWith('/vitrine');
        const queryString = this.href.slice(this.href.indexOf('?'));

        let moengageEventData;
        if (e.currentTarget?.dataset.moengageEventData) {
            try {
                moengageEventData = JSON.parse(e.currentTarget?.dataset.moengageEventData)
            } catch (e) {

            }
        }

        e.preventDefault();
        e.stopPropagation();

        const renderedResponse = await fetch(`/bebida/render_shopping_cart_header.php${queryString}&js-render=true`, {
            method: 'POST',
        });
        const renderedHtml = await renderedResponse.text();
        renderCartHeader(renderedHtml, 'removed');
        handleAbandonedCart();

        if (moengageEventData) {
            sendMoengageEvent('removed_from_cart', moengageEventData);
        }

        if (!isVitrine) {
            renderProducts({}, false);
        }
    });

    function syncFilters() {
        const form = $('.categoryFilter__section__item, .filterList');
        const queryString = window.location.search.substring(1);

        form.find('input[type="checkbox"]').prop('checked', false);
        if ('' !== queryString) {
            queryString.split('&').forEach((param) => {
                const [, values] = param.split('=');

                decodeURIComponent(values).split(',').forEach(value => {
                    $(`#filter${decodeURIComponent(value)}`).prop('checked', true);
                    $(`#filter${decodeURIComponent(value)}-m`).prop('checked', true);
                    $(`#range-price-${decodeURIComponent(value)}`).prop('checked', true);
                    $(`#range-price-${decodeURIComponent(value)}-m`).prop('checked', true);
                })
            });
        }

        form.each(function (i, filter) {
            const filterBlock = $(filter);
            const uncheckedBlocks = filterBlock.find('input:not(:checked)').closest('.checkbox__block').detach();

            uncheckedBlocks.sort(function (secondEl, firstEl) {
                const first = $(firstEl);
                const firstQty = first.data('qty-sku');
                const second = $(secondEl);
                const secondQty = second.data('qty-sku');

                switch (true) {
                    case firstQty > secondQty:
                        return 1;

                    case firstQty < secondQty:
                        return -1;

                    default:
                        return 0;
                }
            });
            filterBlock.append(uncheckedBlocks);
        });
    }

    function showPersonalizationPopup(form) {
        const unitEl = form.find('.js-cartUnit:checked');
        const [factor, unit] = unitEl.val().split(',');
        const quantityEl = form.find('.js-cartQuantity');
        const quantity = parseInt(quantityEl.val() ?? 1) * ('CX' === unit ? parseInt(factor) : 1);


        $.fancybox.open({
            src: `/bebida/${form.data('personalization')}.php`,
            type: 'ajax',
            ajax: {
                settings: {
                    data: {
                        quantity: quantity,
                    }
                }
            },
            smallBtn: false,
            toolbar: false,
            autoFocus: false,
            touch: false,
        });
    }

    $('.js-handleAddProduct')
        .on('click', '.orderBy__link', function (e) {
            const urlParams = {};
            let [, queryString] = $(this).attr('href').split('?');
            queryString ||= '';

            if ('' !== queryString) {
                queryString.split('&').forEach((param) => {
                    const [name, value] = param.split('=');
                    urlParams[decodeURIComponent(name)] = decodeURIComponent(value);
                });
            }

            if (!urlParams['sort']) {
                urlParams['sort'] = null;
            }
            urlParams['page'] = null;
            renderProducts(urlParams);

            e.preventDefault();
        })
        .on('click', '.pagination a', function (e) {
            const urlParams = {};
            const [, queryString] = $(this).attr('href').split('?');

            if ('' !== queryString) {
                queryString.split('&').forEach((param) => {
                    const [name, value] = param.split('=');
                    urlParams[decodeURIComponent(name)] = decodeURIComponent(value);
                });
            }

            if (!urlParams['page']) {
                urlParams['page'] = null;
            }

            renderProducts(urlParams);

            window.scrollTo(0, 0);
            e.preventDefault();
        })
        .on('click', '.js-selected-filters', function (e) {
            /** Remove filtro aplicado na tela principal no mobile */
            const clicked = $(this);
            const urlParams = {};
            const [, queryString] = clicked.attr('href').split('?');

            if ('' !== queryString) {
                queryString.split('&').forEach((param) => {
                    const [name, value] = param.split('=');
                    if ('0' !== value) {
                        urlParams[decodeURIComponent(name)] = decodeURIComponent(value);
                    } else {
                        urlParams[decodeURIComponent(name)] = null;
                    }
                });
            }

            if (!urlParams['page']) {
                urlParams['page'] = null;
            }

            renderProducts(urlParams);
            syncFilters();

            e.preventDefault();
        })
        .on('click', '.js-cartProductMinus', function () {
            const button = $(this);
            const form = button.parents('form');
            const input = button.parent().find("input");
            const maxValue = parseInt(input.data('max'));
            const oldValue = parseFloat(input.val());
            const newValue = parseFloat(oldValue) - 1;
            const isEvent = document.querySelectorAll('.eventPage').length > 0;

            if (!button.hasClass('productItem__quantity--disabled')) {
                if (1 !== maxValue) {
                    form.find('.js-cartProductPlus').removeClass('productItem__quantity--disabled');
                    button.removeClass('productItem__quantity--disabled');
                }

                if (newValue <= 1) {
                    input.val(1);
                    button.addClass('productItem__quantity--disabled');
                }
                input.val(newValue);

                if (
                    !isEvent
                    && undefined === form.data('personalization')
                ) {
                    form.data('updated', 1).submit();
                }
            }
        })
        .on('click', '.js-cartProductPlus', function () {
            const button = $(this);
            const form = button.parents('form');
            const input = button.parent().find("input");
            const oldValue = parseInt(input.val());
            const newValue = parseInt(oldValue) + 1;
            const maxValue = parseInt(input.data('max'));
            const isEvent = document.querySelectorAll('.eventPage').length > 0;

            if (!button.hasClass('productItem__quantity--disabled')) {
                if (1 !== maxValue) {
                    form.find('.js-cartProductMinus').removeClass('productItem__quantity--disabled');
                    button.removeClass('productItem__quantity--disabled');
                }
                if (newValue >= maxValue) {
                    button.addClass('productItem__quantity--disabled');
                }

                // avoid setting amount under 1.
                if (oldValue <= 0) {
                    input.val(1);

                    return;
                }

                // avoid setting amount above max unit
                if (oldValue >= maxValue) {
                    input.val(maxValue);

                    return;
                }

                input.val(newValue);
                if (
                    !isEvent
                    && undefined === form.data('personalization')
                ) {
                    form.data('updated', 1).submit();
                }
            }
        })
        .on('change', '.productPageVariation__input', (ev) => {
            const input = $(ev.currentTarget);
            const form = input.parents('form');

            if (undefined === form.data('personalization')) {
                form.data('updated', 1).submit();
            }
        })
        .on('submit', '.frmCartQuantity', async function (e) {
            const formCart = $(this);
            const productKind = formCart.find('input[name="product_kind"]');
            const isTastingAndTalks = productKind && '2' === productKind.val(); // i.e. � form de evento
            const firstPopupShow = false === cookie.get('cep') && !cookie.get('totem');
            const formData = new FormData(formCart.get(0));
            const units = formCart.find('input[type=radio]:checked').val();
            const factor = parseInt(units?.split(',')[0] ?? 1);
            const unit = units?.split(',')[1] ?? 'UN';

            let cartQuantity = parseInt(formCart.find('.js-cartQuantity')[0].value);
            if ('UN' !== unit) {
                cartQuantity *= factor;
            }

            let moengageEventData;
            try {
                moengageEventData = JSON.parse(formCart.find('input[name="moengage_event_data"]').val());
            } catch (error) {
            }


            async function handleSubmit(reload = false, showPopup = true) {
                if ($('#avisar_email').length > 0) {
                    const responseAvise = await fetch('?action=avise&js-render=true', {
                        method: 'POST',
                        body: formData,
                    });
                    const htmlAvise = await responseAvise.text();
                    formCart.find('.out-of-stock').html(htmlAvise);
                } else if (formCart.find('input[name="products_id[]"]').length > 0) {
                    // i.e. lista de SKUs
                    document.querySelectorAll('.js-aviso-estoque').forEach(el => el.style.display = 'none');

                    [...formCart.get(0).querySelectorAll('input[name="products_id[]"]')].forEach(el => {
                        const quantity = parseInt(document.getElementById(`cart_quantity[${el.value}]`).value);

                        try {
                            moengageEventData = JSON.parse(document.getElementById(`moengage_event_data[${el.value}]`).value);
                            moengageEventData['product_quantity'] = quantity;
                            sendMoengageEvent('added_to_cart', moengageEventData);
                        } catch (error) {
                        }
                    });

                    const renderedResponse = await fetch('/bebida/render_shopping_cart_header.php?action=add_update_product&js-render=true', {
                        method: 'POST',
                        body: formData,
                    });
                    const renderedHtml = await renderedResponse.text();
                    formCart.addClass('added');
                    renderCartHeader(renderedHtml, 'added');
                    handleAbandonedCart();
                    if (showPopup) {
                        handlePopup(formCart);
                    }

                    if (reload) {
                        setTimeout(_ => window.location.reload(), 0);
                    }
                } else {
                    const stock_unit = parseInt(formCart.find('input[name="stock_unit"]').val());
                    const stock_info = '';

                    let cartQuantity = parseInt(formCart.find('.js-cartQuantity')[0].value);

                    window.fCart = formCart;

                    if (cartQuantity > stock_unit) {
                        document.querySelectorAll('.js-aviso-estoque').forEach(el => el.style.display = 'block');

                        return false;
                    } else {
                        document.querySelectorAll('.js-aviso-estoque').forEach(el => el.style.display = 'none');

                        const renderedResponse = await fetch('/bebida/render_shopping_cart_header.php?action=add_update_product&js-render=true', {
                            method: 'POST',
                            body: new FormData(formCart.get(0)),
                        });
                        const renderedHtml = await renderedResponse.text();
                        formCart.addClass('added');
                        renderCartHeader(
                            renderedHtml,
                            0 === formCart.data('updated') || undefined === formCart.data('updated')
                                ? 'added'
                                : 'updated'
                        );
                        handleAbandonedCart();
                        if (showPopup) {
                            handlePopup(formCart);
                        }

                        if (moengageEventData) {
                            moengageEventData['product_quantity'] = ('UN' !== unit ? factor : 1) * cartQuantity;
                            sendMoengageEvent('added_to_cart', moengageEventData);
                        }

                        if (reload) {
                            setTimeout(_ => window.location.reload(), 0);
                        }
                    }
                }
            }

            switch (true) {
                case undefined !== formCart.data('personalization'):
                    showPersonalizationPopup(formCart);

                    e.preventDefault();
                    break;

                case firstPopupShow && !isTastingAndTalks:
                    $.fancybox.open({
                        src: '/bebida/popup_stock.php',
                        type: 'ajax',
                        modal: true,
                        beforeClose: _ => {
                            fetch(
                                window.location.pathname,
                                {
                                    method: 'POST',
                                    body: new FormData(document.querySelector('.popupStock__form'))
                                })
                                .then(res => res.json())
                                .then(data => {
                                    const urlProduto = formCart.closest('.productItem').find('.product__link').attr('href');

                                    if (0 === data.store) { // i.e. Regi�o n�o atendida
                                        if (
                                            formCart.find('input[name="products_id[]"]').length > 0
                                            || document.querySelector('.productPage')
                                        ) {
                                            window.location.reload();
                                        } else {
                                            const productId = formCart.find('input[name="products_id"]').val();
                                            window.location.href = urlProduto;
                                        }
                                    } else {
                                        handleSubmit(false, false);
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                            ;
                        }
                    });
                    e.preventDefault();
                    break;

                case !isTastingAndTalks:
                    handleSubmit();
                    e.preventDefault();
                    break;

                default:
                    e.preventDefault();

                    if (moengageEventData) {
                        moengageEventData['product_quantity'] = ('UN' !== unit ? factor : 1) * cartQuantity;
                        sendMoengageEvent('added_to_cart', moengageEventData);
                    }
                    await fetch(formCart.attr('action'), {
                        method: 'POST',
                        body: formData,
                    });

                    setTimeout(_ => window.location = formCart.attr('action'), 0);
                    break;
            }
        })
    ;

    const removeDiacritics = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    $('form[name="filter"]')
        .on('keyup', '.js-attributeSearch', function () {
            /** Mostra somente atributos/valores do filtro que "casam" com a string */
            const searchEl = $(this);
            const searchTxt = removeDiacritics($(this).val().toLocaleLowerCase());
            const searchTarget = searchEl.closest('.categoryFilter__section, .js-filterSub')
                .find('.categoryFilter__section__item, .filterList')
                .find('.checkbox__block');

            if ('' !== searchTxt) {
                searchEl.siblings('.js-attributeSearchClear').show();
            } else {
                searchEl.siblings('.js-attributeSearchClear').hide();
            }
            searchTarget.each(function (index, target) {
                const targetTxt = removeDiacritics((target.textContent || target.innerText).trim().toLocaleLowerCase());

                if (targetTxt.includes(searchTxt)) {
                    target.style.display = 'block';
                } else {
                    target.style.display = 'none';
                }
            });
        })
        .on('click', '.js-attributeSearchClear', function () {
            /** Limpa busca do atributo/valor do filtro */
            const searchEl = $(this).siblings('.js-attributeSearch');

            searchEl.val('').keyup();
        })
        .on('change', 'input[type="checkbox"], input[type="radio"]', function () {
            /** Filtra os produtos baseado na escolha (click) do valor */
            const field = $(this);
            const fieldName = field.attr('name');
            const filterBlock = field.closest('.categoryFilter__section__item, .filterList');
            const form = field.closest('form');
            const formFields = form.serializeArray();
            const urlParams = {};
            const priceFrom = form.find('input[name="pfrom"]');
            const priceTo = form.find('input[name="pto"]');

            if (formFields.length > 0) {
                formFields.forEach(function (field) {
                    const fieldName = field.name.replace('[]', '');
                    if (urlParams[fieldName]) {
                        urlParams[fieldName].push(field.value);
                    } else {
                        urlParams[fieldName] = [field.value];
                    }
                });
                Object.keys(urlParams).map(function (key) {
                    urlParams[key] = urlParams[key].join(',');
                });
            }
            if (0 === form.find(`[name="${fieldName}"]:checked`).length) {
                urlParams[fieldName.replace('[]', '')] = null;
            }
            urlParams['page'] = null;
            renderProducts(urlParams);

            /** Bloqueia sele��o quando atinge 5 op��es do mesmo filtro */
            if (filterBlock.find('input[type="checkbox"]:checked').length > 4) {
                filterBlock.find('input[type="checkbox"]:not(:checked)').attr('disabled', 'disabled');
            } else {
                filterBlock.find('input[type="checkbox"]:not(:checked)').removeAttr('disabled', 'disabled')
            }

            if (field.prop('checked')) {
                field.closest('.checkbox__block').detach().prependTo(filterBlock);
                filterBlock.get(0).scrollTo(0, 0);
            } else {
                field.closest('.checkbox__block').detach().appendTo(filterBlock);
            }

            const uncheckedBlocks = filterBlock.find('input:not(:checked)').closest('.checkbox__block').detach();
            uncheckedBlocks.sort(function (secondEl, firstEl) {
                const first = $(firstEl);
                const firstQty = first.data('qty-sku');
                const second = $(secondEl);
                const secondQty = second.data('qty-sku');

                switch (true) {
                    case firstQty > secondQty:
                        return 1;

                    case firstQty < secondQty:
                        return -1;

                    default:
                        return 0;
                }
            });
            filterBlock.append(uncheckedBlocks);

            if ('range-price[]' === fieldName) {
                if (0 === filterBlock.find(`input:checked`).length) {
                    priceFrom.val(priceFrom.data('value')).prop('disabled', false);
                    priceTo.val(priceTo.data('value')).prop('disabled', false);
                } else {
                    priceFrom.data('value', priceFrom.val()).val('').prop('disabled', true);
                    priceTo.data('value', priceTo.val()).val('').prop('disabled', true);
                }
            }
        })
        .on('click', '.button--clear, .filter__button--clear', function (e) {
            /** Limpa todos os filtros */
            const filters = $(this).closest('form').find('input[type="checkbox"]');
            const prices = $(this).closest('form').find('input[type="tel"]');
            const urlParams = {};
            const queryString = window.location.search.substring(1);
            const isFilterOpen = (0 !== $('.action__filter').length);

            filters
                .prop('checked', false)
                .prop('disabled', false)
            ;
            prices.val('');

            /**  Recupera a "query string" ?a=1&b=2 em formato {a: 1, b:2} */
            if ('' !== queryString) {
                queryString.split('&').forEach((param) => {
                    const [name,] = param.split('=');

                    /** Se for busca, N�O limpa */
                    if ('q' !== name) {
                        urlParams[decodeURIComponent(name)] = null;
                    }
                });
            }
            urlParams['page'] = null;
            renderProducts(urlParams);

            if (isFilterOpen) {
                $('html').removeClass('action__filter');
            }
            e.preventDefault();
        })
        .on('submit', function (e) {
            const form = $(this);
            const formFields = form.serializeArray();
            const isFilterOpen = (0 !== $('.action__filter').length);
            const urlParams = {};

            if (formFields.length > 0) {
                formFields.forEach(function (field) {
                    const fieldName = field.name.replace('[]', '');
                    if (urlParams[fieldName]) {
                        urlParams[fieldName].push(field.value);
                    } else {
                        urlParams[fieldName] = [field.value];
                    }
                });
                Object.keys(urlParams).map(function (key) {
                    urlParams[key] = urlParams[key].join(',');
                });
            }
            urlParams['page'] = null;
            renderProducts(urlParams);


            if (isFilterOpen) {
                $('html').removeClass('action__filter');
            }
            e.preventDefault();
        })
    ;

    $('.categoryFilter, .filter__container--categories')
        .on('click', '.categoryFilter__section__title', function (e) {
            const linkUrl = $(this).get(0)?.href;

            if (linkUrl) {
                const params = Object.fromEntries((new URL(linkUrl)).searchParams.entries())

                params['page'] = null;
                renderProducts(params, true);

                $('html').removeClass('action__filter');
                e.preventDefault();
            }
        })
        .on('click', '.btns a', function (e) {
            renderProducts(
                {
                    'cPath': null,
                    'page': null,
                    'cid': null,
                },
                true
            );

            e.preventDefault();
        });

    function renderProducts(params, updateUrl = true) {
        const urlParams = {};
        const queryString = window.location.search.substring(1);

        /** Ajusta string de pre�os para padr�o americano */
        if (params['pfrom']) {
            params['pfrom'] = params.pfrom.replace('.', '').replace(',', '.');
        }
        if (params['pto']) {
            params['pto'] = params.pto.replace('.', '').replace(',', '.');
        }

        /**  Recupera a "query string" ?a=1&b=2 em formato {a: 1, b:2} */
        if ('' !== queryString) {
            queryString.split('&').forEach((param) => {
                const [name, value] = param.split('=');
                urlParams[decodeURIComponent(name)] = decodeURIComponent(value);
                if (undefined === value) {
                    urlParams[decodeURIComponent(name)] = '';
                }
            });
        }

        /** Remove campos de filtros quando n�o tem nenhum marcado */
        Object.keys(params).map(field => {
            if (
                null === params[field]
                || '' === params[field]
            ) {
                delete params[field];
                delete urlParams[field];
            }
        });

        const searchParams = new URLSearchParams({...urlParams, ...params});
        const url = new URL(`${window.location.origin}${window.location.pathname}?${searchParams.toString()}`);

        if (updateUrl) {
            history.pushState({}, '', url);
        }

        url.searchParams.append('js-render-products', '1');

        fetch(url)
            .then(function (res) {
                return res.json();
            })
            .then(data => {
                const desktopProductsList = $('.categoryListing__list');
                if (data.empty) {
                    desktopProductsList.addClass('categoryListing__empty');
                } else {
                    desktopProductsList.removeClass('categoryListing__empty');
                }
                $('.js-orderBy').html(data.order);
                desktopProductsList.html(data.desktop.html);
                $('.categoryPage__products').html(data.mobile.html);
                if (null !== data.title) {
                    $('.categoryPage__title span').html(data.title);
                    $('.categoryListing__header .categoryListing__title').html(data.title);
                    $('title').html(`${data.title} - Imigrantes Bebidas`);
                }
                renderOffers();
            })
            .catch(error => {
                console.log(error);
            })
        ;
    }

    window.addEventListener('popstate', (event) => {
        renderProducts({}, false);
        syncFilters();
    });

    $('.js-shortDescription').on('click', '.show-more', function () {
        $(this).closest('.js-shortDescription').addClass('shown');
    });

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                const target = document.getElementsByClassName('js-productFooter')?.item(0);
                if (target) {
                    if (entry.isIntersecting) {
                        target.classList.remove('shown');
                    } else {
                        target.classList.add('shown');
                    }
                }
            });
        },
        {
            root: null,
            threshold: 0,
            rootMargin: '-128px 0px 0px 0px',
        }
    );
    [...document.getElementsByClassName('js-productDetails')].forEach(element => observer.observe(element));
    [...document.querySelectorAll('.eventPage .js-cartQuantity')].forEach(
        el => el.addEventListener("focusout", function (e) {
            const input = $(e.target);
            const newValue = parseInt(input.val());
            const maxValue = parseInt(e.target.dataset.max);

            // avoid setting amount under 1.
            if (newValue <= 0) {
                input.val(1);
                return;
            }

            // avoid setting amount above max unit
            if (newValue >= maxValue) {
                input.val(maxValue)
            }
        })
    );

    [...document.querySelectorAll('.highlights, .promotions, .categoryPage__content, .categoryListing, .productPage, .see-also')]
        .forEach(el => el.addEventListener("focusout", function (e) {
                if (e.target.classList.contains('js-cartQuantity')) {
                    const input = $(e.target);
                    const form = input.closest('form');
                    const newValue = parseFloat(input.val());
                    const maxValue = parseFloat(e.currentTarget.dataset.max);

                    // avoid setting amount under 1.
                    if (newValue <= 0) {
                        input.val(1);
                        return;
                    }

                    // avoid setting amount above max unit
                    if (newValue >= maxValue) {
                        input.val(maxValue)
                    }

                    if (undefined === form.data('personalization')) {
                        form.data('updated', 1).submit();
                    }
                }
            })
        );

    // const heroBannersContainer = document.querySelector('.fullbanner');
    // if (heroBannersContainer) {
    //     const imagesContainer = heroBannersContainer?.querySelector('.fullbanner__mobile');
    //
    //     imagesContainer.addEventListener('mouseenter', e => {
    //         heroBannersTimer.stop();
    //     });
    //     imagesContainer.addEventListener('mouseleave', e => {
    //         heroBannersTimer.start();
    //     });
    //     imagesContainer.addEventListener('swiped', e => {
    //         heroBannersTimer.stop();
    //         const dots = heroBannersContainer.querySelectorAll('.fullbanner__dot');
    //         const activeDot = heroBannersContainer.querySelector('.fullbanner__dot--active');
    //         let toDot = +activeDot.dataset.index;
    //
    //         if (e.detail.dir === 'left') {
    //             toDot += 1;
    //         } else {
    //             toDot -= 1;
    //         }
    //         toDot = Math.min(Math.max(toDot, 0), dots.length - 1);
    //         dots[toDot].click();
    //         // heroBannersTimer.start();
    //         // $('.fullbanner__container').css('backgroundColor', '#' + Math.floor(Math.random() * 16777215).toString(16));
    //     });
    //
    //     const heroBannersTimer = new _setInterval(_ => {
    //         const dots = heroBannersContainer.querySelectorAll('.fullbanner__dot');
    //         const activeDot = heroBannersContainer.querySelector('.fullbanner__dot--active');
    //         if (activeDot) {
    //             let toDot = +activeDot.dataset.index + 1;
    //
    //             if (toDot >= dots.length) {
    //                 toDot = 0;
    //             }
    //             dots[toDot].click();
    //         }
    //     }, 4000);
    //
    //     heroBannersContainer?.querySelectorAll('.fullbanner__dot').forEach(el => el.addEventListener('click', e => {
    //         const button = e.currentTarget;
    //         const activeDot = heroBannersContainer.querySelector('.fullbanner__dot--active');
    //
    //         heroBannersTimer.stop();
    //         if (activeDot !== button) {
    //             activeDot.classList.remove('fullbanner__dot--active');
    //             button.classList.add('fullbanner__dot--active');
    //         }
    //         imagesContainer.scrollTo({
    //             top: 0,
    //             left: +button.dataset.index * imagesContainer.offsetWidth,
    //             behavior: "smooth"
    //         });
    //         heroBannersTimer.start();
    //         // $('.fullbanner__container').css('backgroundColor', '#'+Math.floor(Math.random()*16777215).toString(16));
    //     }));
    //
    //     heroBannersContainer?.querySelectorAll('.prev, .next').forEach(el => el.addEventListener('click', e => {
    //         const button = e.currentTarget;
    //         const dots = heroBannersContainer.querySelectorAll('.fullbanner__dot');
    //         const activeDot = heroBannersContainer.querySelector('.fullbanner__dot--active');
    //
    //         let dotIndex = +activeDot.dataset.index;
    //
    //         heroBannersTimer.stop();
    //         if (button.classList.contains('prev')) {
    //             dotIndex--;
    //         } else {
    //             dotIndex++;
    //         }
    //         if (
    //             dotIndex >= 0
    //             && dotIndex < dots.length
    //         ) {
    //             dots[dotIndex].click();
    //         }
    //         heroBannersTimer.start();
    //     }));
    // }

    /** filterHandler */
    (_ => {
        const sidebarElement = document.querySelector(".js-sidebarOpen");
        const sidebarCloseElement = document.querySelectorAll(".js-sidebarClose, .js-sidebarUtil");
        const filterChildrenElements = document.querySelectorAll(".js-filterOpen");
        const toggleFilterClass = "action__filter";
        const bodyElement = document.querySelector("html");
        const filterButtonElement = document.querySelector(".js-filterButton");

        let filterInputElement = document.querySelectorAll(".filter__input");

        if (!Ensure.isUndefined(sidebarElement)) {
            sidebarElement.addEventListener("click", function (e) {
                e.preventDefault();

                bodyElement.classList.toggle(toggleFilterClass);
            });

            [...sidebarCloseElement].map(item => {
                item.addEventListener("click", function (e) {
                    e.preventDefault();

                    bodyElement.classList.toggle(toggleFilterClass);
                });
            });

            [...filterChildrenElements].forEach((children, index) => {
                const listItem = children.parentElement;

                children.addEventListener("click", function (e) {
                    e.preventDefault();
                    listItem.classList.add("filter__item--open");
                });

                const buttonBack = listItem.querySelector('.js-filterBack');

                buttonBack.addEventListener("click", function (e) {
                    e.preventDefault();

                    listItem.classList.remove("filter__item--open");
                });
            });

            [...filterInputElement].forEach(input => {
                input.addEventListener("click", () => {
                    if ($(".check").is(":checked")) {
                        filterButtonElement.classList.add("filter__button--enabled");
                    } else {
                        filterButtonElement.classList.remove("filter__button--enabled");
                    }
                });
            });
        }
    })();

    /** orderByHandler */
    (_ => {
        const orderByElement = ".js-orderBy";

        if (!Ensure.isUndefined(orderByElement)) {
            $(orderByElement)
                .on("click", function () {
                    let _this = $(this);

                    _this.toggleClass("orderBy--active");
                })
                .on('mouseleave', function (e) {
                    $(this).removeClass("orderBy--active");
                });

            $(document).on("click", function (e) {
                if (0 === $(e.target).closest('.js-orderBy').length) {
                    $(orderByElement).removeClass("orderBy--active");
                }
            });
        }
    })();

    /** listBtnTypesHandler */
    (_ => {
        const buttonList = document.querySelector('#btn-list');
        const buttonGrid = document.querySelector('#btn-grid');
        const layoutContainer = document.querySelector('#c_list');

        // Events for Grid & List - Category
        const setCookie = (cookieID, cookieValue, cookieDays) => {
            const cookieDate = new Date();
            const cookieExpiresTime = cookieDate.getTime() + (cookieDays * 24 * 60 * 60 * 1000);

            cookieDate.setTime(cookieExpiresTime);
            document.cookie = `${cookieID}=${cookieValue};${cookieDate.toUTCString()};path=/`;
        }

        const removeCookie = (cookieID) => {
            setCookie(cookieID, '', 0);
        }

        if (!Ensure.isUndefined(buttonList)) {
            buttonList.addEventListener('click', e => {
                e.preventDefault();

                const currentActive = document.querySelector('.grid-view__button--active');

                if (currentActive) {
                    currentActive.classList.remove("grid-view__button--active");
                }

                layoutContainer.classList.add("list__list");
                buttonList.classList.add("grid-view__button--active");

                setCookie('productListlayout', 'list', 90);
            });
        }
        if (!Ensure.isUndefined(buttonGrid)) {
            buttonGrid.addEventListener('click', e => {
                e.preventDefault();

                const currentActive = document.querySelector('.grid-view__button--active');

                if (currentActive) {
                    currentActive.classList.remove("grid-view__button--active");
                }

                layoutContainer.classList.remove("list__list");
                buttonGrid.classList.add("grid-view__button--active");

                removeCookie('productListlayout');
            });
        }
    })();
});
